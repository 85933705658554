import { render, staticRenderFns } from "./LineUAdmissionEntered.vue?vue&type=template&id=bb5ed5fe&scoped=true&"
import script from "./LineUAdmissionEntered.vue?vue&type=script&lang=js&"
export * from "./LineUAdmissionEntered.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bb5ed5fe",
  null
  
)

export default component.exports